<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "AppLayoutDefault"
})
</script>

<template>
    <slot />
</template>

<style scoped>

</style>
