<script>
export default {
    name: 'QuizBid',
    props: {
        quiz_id: { type: Number, required: true },
        is_modal: { type: Boolean, default: false },
        check: { type: Boolean, default: false },
    },
    data() {
        return {
            isVisible: true,
            isBidSaved: undefined,
            promo_html: '',
        }
    },
    computed: {
        isShowContent() { return this.is_modal ? (this.isVisible && !this.isBidSaved) : true; },
        componentType() { return this.is_modal ? 'qip-modal' : 'div' },
    },
    mounted() {
        window.vueQuizBid = this;
        if (this.check) this.doCheck();
    },
    methods: {
        doCheck() {
            this.isVisible = false; // Hide button while waiting request
            this.isBidSaved = true;

            this.axios.get(`quiz/${this.quiz_id}/bid`).then(resp => {
                if (!resp.data.levels.includes(this.$store.class.course.level)) {
                    this.isVisible = false;
                    return;
                }

                this.isBidSaved = resp.data.bid;
                this.promo_html = resp.data.promo_html;
                this.isVisible = true;

                console.log(this.isVisible);

            }).catch(err => {
                this.$notify.fail(`Виникла непередбачувана помилка при перевірці заявки на участь у Quiz`);
            });
        },
        bid(e) {
            if (e && e.target?.classList.contains('disabled')) return;

            if (!this.$store.me.school.id) {
                this.$notify.promo(`Для участі у грі необхідно вказати навчальний заклад на сторінці «<a href="/profile">Профілю</a>»`);
                return;
            }

            this.axios.post(`quiz/${this.quiz_id}/bid`).then(() => {
                this.$notify.success(`Заявку прийнято`);
                this.isBidSaved = true;
            }).catch(err => {
                this.$notify.fail(`Виникла непередбачувана помилка при подачі заявки`);
            });
        }
    }
}
</script>

<template>
    <component :is="componentType" :class="{'kmm promo': is_modal}" :opened="true" v-if="isShowContent" :key="isShowContent">
        <div v-if="isVisible" style="max-width: 640px; " :class="{'kmm promo': !is_modal}">
            <div class="quiz-bid" style="padding: 10px">
                <div class="content" v-html="promo_html"></div>
                <img src="https://tmp.onedev.net/quiz-button.png" alt="quiz button" class="quiz-button" :class="{'disabled': isBidSaved}"
                     @click="bid" :title="isBidSaved ? 'Зареєстровано' : 'Подати заявку на участь у грі «Quiz: Рекорд України»'"/>
                <div style="font-weight: bold">{{ isBidSaved ? 'Заявку на участь зареєстровано 👍' : '' }}</div>
                <!--<button class="kmm primary" @click="bid" :disabled="isBidSaved">
                    {{ isBidSaved ? 'Заявку подано' : 'Подати заявку' }}
                </button>-->
            </div>
        </div>
    </component>
</template>

<style scoped>
    .quiz-bid { display: flex; flex-direction: column; align-items: center; gap: 10px; margin: 10px auto; max-width: 640px; }
    .quiz-bid .content { text-align: center; }
    button:disabled { opacity: 0.8; }
    .quiz-button { height: 76px; cursor: pointer; transition: all .25s linear; }
    .quiz-button:hover:not(.disabled) { transform: scale(1.05); }
    .quiz-button.disabled { opacity: 0.8; cursor: default; }
</style>