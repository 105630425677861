//export default { install: (app, options) => {} }
export default function (app, options) {
    // @see https://v3.ru.vuejs.org/ru/guide/plugins.html#%D1%81%D0%BE%D0%B7%D0%B4%D0%B0%D0%BD%D0%B8%D0%B5-%D0%BF%D0%BB%D0%B0%D0%B3%D0%B8%D0%BD%D0%B0
    // @see https://izitoast.marcelodolza.com/
    const iziToast = require('izitoast/dist/js/iziToast.min' /* webpackChunkName: "izitoast" */);
    import('izitoast/dist/css/iziToast.min.css' /* webpackChunkName: "izitoast" */);

    iziToast.settings({
        timeout: 5000,
        position: "bottomRight", //  bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
        resetOnHover: true, // Reset the toast timeout while the cursor is on it.
        //overlay: false,
        //icon: "material-icons",
        //iconText: "done", // ? - not works
        //color: "", // blue, red, green, yellow
        //balloon: "false", // Applies a balloon like toast.
        //close: true,
        //closeOnEscape: true,
        //closeOnClick: true,
        //displayMode: 0, // 0, 1, 2 (1 - once, 2 - replace mode)
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
        // onOpening: function () {
        //
        // },
        // onClosing: function () {
        //
        // },
        // buttons: [
        //     ['<button>Зрозуміло!</button>', function() { alert("OK"); }]
        // ]
    });

    window.iziToast = iziToast;

    // help 🛟 🎈 🎃 🎁 🏆 🥇 🥈 🥉 🏁 🚩
    // 🔔 📢 📣 ⏰
    // 💌 🌱
    // ✓ 🗸 ✅
    // ⚠ ⚠️ ❗ ❌ ‍🩹 🐞 🛑 🚨

    const $notify = {
        success(text, opts) {
            opts = Object.assign({title: "", message: text, color: "green", icon: "none", iconText: "✓", timeout: 3000}, opts || {});
            return iziToast?.show(opts);
        },
        fail(text, opts) {
            // @todo Что произошло. Почему. Что можно предпринять. Кому сообщить.
            opts = Object.assign({title: "", message: text, color: "red", icon: "none", iconText: "🐞", timeout: 15000}, opts || {});
            return iziToast?.show(opts); // ! 🐞
            //return iziToast?.error({titme: '', message: text}); // @todo Check it error(), warning(), info(),
        },
        alert(text, opts) {
            return iziToast?.show({title: "", message: text, color: "yellow", icon: "none", iconText: "☝"});
        },
        info(text, opts) {
            return iziToast?.show({title: "", message: text, color: "blue", icon: "none", iconText: "🛈"});
        },
        confirm(text, opts) {
            // @todo
            //{ id: 'question', close: false, overlay: true, displayMode: 'once',}
        },
        critical(text, opts) {
            // @todo
        },
        promo(text, opts, cb) {
            //window.iziToast?.destroy(this.closest('.iziToast'));
            //window.iziToast?.hide({}, this.closest('.iziToast'));
            opts = Object.assign({
                title: "",
                message: `<div class="iziToastPromoMessage"><div>${text}</div><div><button onclick="window.iziToast?.hide({}, this.closest('.iziToast'));" style="">Зрозуміло!</button></div></div>`,
                timeout: 0,
                close: false,
                closeOnEscape: true,
                progressBar: false,
                position: "center",
                overlay: true,
                color: "yellow",
                icon: "none",
                iconText: "",
                layout: 2, // Medium Layout
                displayMode: 'once', // 'once' | 1 = Waits until the toast is closed so you can open it
            }, opts || {});

            return iziToast?.show(opts); // Ничего не возвращает!
        },
        promoOnce(key, text, opts) {
            if (!localStorage.getItem(key) && !document.querySelector('.iziToast-capsule')) {
                $notify.promo(text);
                localStorage.setItem(key, 'done');
            }
        }
    };

    app.config.globalProperties.$notify = $notify;
    //app.provide('notify', $notify);

    //app.provide('i18n', options); // @note Get: inject['i18n']

    /*app.directive('my-directive', {
        mounted (el, binding, vnode, oldVnode) {
            // какая-то логика ...
        },
        ...
    })*/

    /*app.mixin({
        created() {
            // какая-то логика ...
        }
        ...
    })*/
}
