<script>
import PermissionModals from "@/components/PermissionModals.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import {isWildcard} from "eslint-plugin-vue/lib/utils/indent-utils";
import {useDialog} from "primevue/usedialog";

export default {
    name: 'App',
    components: {AppLayout, PermissionModals},
    data() {
        return {
            runNextMount: false,
        }
    },
    computed: {
        buildVersion() {
            return process.env.VUE_APP_BUILD_VERSION
        },
    },
    errorCaptured(err) {
        const report = err.message + ' at ' + window.location.pathname;
        window.umami?.track('errorCaptured:', {report: report, ua: window.navigator.userAgent, user_id: this.$store.me.id});
    },
    async mounted() {
        // @note this.$route.name is undefined! Because this component is outside `<router-view></router-view>`

        const params = new URLSearchParams(location.search);

        if (params.get('k')) {
            localStorage.setItem('accessKey', params.get('k'));
        }

        // Short Session
        if (params.get('s')) {
            sessionStorage.setItem('accessKey', params.get('s'));
        }

        console.log(location.pathname)
        if (!this.$store.accessKey  && !location.pathname.match(/^(\/auth|\/game\/|\/guest\/|\/war-memory)/)) {
            this.$router.replace('/auth');
        }

        if (this.$store.accessKey) {
            await this.$store.auth();
            // @note Це гарантує, що решта компонентів будуть монтуватися вже після отримання `this.$store.me`
            await this.$store.init();

            if (location.pathname === '/auth') {
                this.$router.replace('/subjects');
            }
        }

        if (!this.$store.accessKey) this.runNextMount = true;
        if (this.$store.me.id) this.runNextMount = true;

        if (!this.runNextMount) {
            // Fix: Ініціалізація після "просипання" вкладки
            this.$store.callEveryMinutes('runNextMountRedirect', 5, () => {
                location.reload();
            });
        }

        this.initTracker(() => {
            this.checkSupportFeatures();
        });
    },
    methods: {
        initTracker(onload) {
            if (process.env.NODE_ENV !== 'production') return;
            if (this.$store.me.is_tester) return;

            // ~2 kb, 250 ms
            if (this.$store.me.is_teacher) {
                const script = document.createElement('script');
                script.defer = true;
                script.src = 'https://umami.edprofi.com.ua/script.js';
                script.setAttribute('data-website-id', '938a269e-9832-4674-ad8a-433af672677b');
                script.onload = onload;
                document.body.appendChild(script);
            } else if (this.$store.me.is_student) {
                const script = document.createElement('script');
                script.defer = true;
                script.src = 'https://umami.edprofi.com.ua/script.js';
                script.setAttribute('data-website-id', '50457cb6-e293-4d6e-8786-594330b0d28c');
                script.onload = onload;
                document.body.appendChild(script);
            }
        },
        async checkSupportFeatures() {
            /*function canUseWebp() {
                const elem = document.createElement('canvas');
                if (elem.getContext && elem.getContext('2d')) {
                    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0; // @note Возвращает data:image/png в iOS
                }
                return false; // @note Very old browser like IE 8, canvas not supported
            }
            const isSupportWebp = canUseWebp();*/

            const isSupportWebp = await (async function () {
                return new Promise(resolve => {
                    const image = new Image();
                    image.addEventListener('error', () => resolve(false));
                    image.addEventListener('load', () => resolve(image.width === 1));
                    image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
                }).catch(() => false);
            }());

            if (!isSupportWebp) {
                console.log('not support webp:', isSupportWebp);
                if (!localStorage.getItem('track-not-support-webp')) {
                    window.umami?.track('not-support-webp', { user_id: this.$store.me.id, ua: window.navigator.userAgent});
                    localStorage.setItem('track-not-support-webp', '1');
                }
            }
        },
    },
}
</script>

<template>
    <template v-if="runNextMount">
        <AppLayout />

        <kmm-footer v-if="!['quizGame', 'warMemory', 'warMemoryInfo'].includes(this.$route.name)" />
        <div id="appModals"></div>
        <div :title="'Версія кабінету: ' + buildVersion" style="position: fixed; bottom: 5px; right: 5px; color: #c9c9c9; font-size: 11px">{{ buildVersion }}</div>
        <permission-modals></permission-modals>
    </template>
    <template v-else-if="waitAjaxRef.value > 0">
        <div class="qip-loading" style="height: 100dvh;"></div>
    </template>
</template>
