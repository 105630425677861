import {onMounted, ref, watch} from "vue";


export function useLocalStorage(key, defaultValue) {
    const data = ref(defaultValue);

    onMounted(() => {
        const item = localStorage.getItem(key);
        data.value = item ? JSON.parse(item) : defaultValue;
    });

    watch(data, (newValue) => {
        localStorage.setItem(key, JSON.stringify(newValue));
    });

    return data;
}