<script>
export default {
    name: 'KmmFooter',
    mounted() {
        setTimeout(() => {
            const isHide = ['guestTest'].includes(this.$route.name);
            isHide && this.$refs.footer?.classList.add('hide');
        }, 500);
    },
}
</script>

<template>
    <footer class="footer" ref="footer">
        <div class="footer__content">
            <div class="footer__contacts-text">
                <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 0C3.81 0 0 3.81 0 8.5C0 13.19 3.81 17 8.5 17H9V20C13.86 17.66 17 13 17 8.5C17 3.81 13.19 0 8.5 0ZM7.5 14.5V12.5H9.5V14.5H7.5ZM9.86997 9.77005C9.87997 9.75005 9.88997 9.73005 9.89997 9.72005C10.1721 9.3209 10.5513 8.98849 10.9356 8.65151C11.8223 7.87407 12.7369 7.07222 12.43 5.37005C12.14 3.68005 10.79 2.29005 9.09997 2.04005C7.03997 1.74005 5.21997 3.01005 4.66997 4.83005C4.49997 5.41005 4.93997 6.00005 5.53997 6.00005H5.73997C6.14997 6.00005 6.47997 5.71005 6.61997 5.35005C6.93997 4.46005 7.87997 3.85005 8.91997 4.07005C9.87997 4.27005 10.58 5.22005 10.49 6.20005C10.4222 6.96359 9.87677 7.38589 9.27146 7.8545C8.89375 8.14692 8.49274 8.45738 8.16997 8.88005L8.15997 8.87005C8.14264 8.88738 8.12865 8.91139 8.11413 8.93629C8.10352 8.95449 8.09264 8.97316 8.07997 8.99005C8.06497 9.01505 8.04747 9.04005 8.02997 9.06504C8.01247 9.09005 7.99497 9.11505 7.97997 9.14005C7.88997 9.28005 7.81997 9.42005 7.75997 9.58005C7.75497 9.60005 7.74497 9.61505 7.73497 9.63005C7.72497 9.64505 7.71497 9.66005 7.70997 9.68005C7.69997 9.69005 7.69997 9.70005 7.69997 9.71005C7.57997 10.07 7.49997 10.5 7.49997 11.01H9.50997C9.50997 10.76 9.53997 10.54 9.60997 10.33C9.62997 10.26 9.65997 10.19 9.68997 10.12C9.69997 10.08 9.70997 10.04 9.72997 10.01C9.76997 9.93006 9.81996 9.85006 9.86996 9.77007L9.86997 9.77005Z" fill="white"/>
                </svg>
                <span style="margin-left: 10px;">Для замовлення консультацій:</span>
            </div>

            <a class="footer__contacts-phone" href="tel:+38 (099) 6 800 900">+38 (099) 6 800 900</a>
            <a class="footer__contacts-phone" href="tel:+38 (097) 6 800 900">+38 (097) 6 800 900</a>
        </div>
    </footer>
</template>

<style scoped>
.footer {
    min-height: 40px;
    padding: 10px 0;
    background: var(--primary-text);
    position: relative;
    display: flex;
    justify-content: center;
}

.footer__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.footer__contacts-text {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: #fff;
    margin-right: 7px;
}
.footer__contacts-phone {
    color: #e82962;
    margin-right: 10px;
}
.footer__contacts-phone:hover { color: #fff; }
</style>
