<script setup>
import {onMounted, ref} from 'vue';
import AllowCookiesModal from '@/components/modals/AllowCookiesModal.vue';
import ProcessDataModal from '@/components/modals/ProcessDataModal.vue';
import { useLocalStorage } from '@/helpers/useLocalStorage'; // assume this is a composable that handles local storage

const isCookieAllowed = useLocalStorage('isCookieAllowed', false);
const isDataPermissionAllowed = useLocalStorage('isDataPermissionAllowed', false);

const currentModal = ref(null);

onMounted(() => {
  if (!isCookieAllowed.value) {
    currentModal.value = 'cookies';
  } else if (!isDataPermissionAllowed.value) {
    currentModal.value = 'data';
  }
})

const closeModal = () => {
  currentModal.value = null;
};

const allowCookies = () => {
  isCookieAllowed.value = true;

  if (!isDataPermissionAllowed.value) {
    currentModal.value = 'data';
  } else {
    closeModal();
  }
};

const disallowCookies = () => {
  isCookieAllowed.value = false;

  if (!isDataPermissionAllowed.value) {
    currentModal.value = 'data';
  } else {
    closeModal();
  }
};

const allowDataPermission = () => {
  isDataPermissionAllowed.value = true;
  closeModal();
};

const disallowDataPermission = () => {
  isDataPermissionAllowed.value = false;
  closeModal();
};
</script>

<template>
  <allow-cookies-modal v-if="currentModal === 'cookies' && $store.accessKey"
                       @allowed="allowCookies"
                       @disallowed="disallowCookies">
  </allow-cookies-modal>

  <process-data-modal v-if="currentModal === 'data' && $store.accessKey"
                      @allowed="allowDataPermission"
                      @disallowed="disallowDataPermission">
  </process-data-modal>
</template>
