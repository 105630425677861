<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'AppLayoutUser',
    mounted() {}
})
</script>

<template>
    <main class="wrapper">
        <kmm-nav />
        <slot />
    </main>
</template>
